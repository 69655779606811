/* App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.header h1 {
  font-size: 3em;
  color: #ff6f61;
}

.header h2 {
  font-size: 2em;
  color: #6b5b95;
}

.product-intro {
  text-align: center;
  margin-bottom: 40px;
}

.product-intro p {
  font-size: 1.2em;
  line-height: 1.6;
}

.steps, .values, .advantages {
  margin-bottom: 40px;
}

.steps h3, .values h3, .advantages h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.steps ol, .values ul, .advantages ul {
  list-style-type: none;
  padding: 0;
}

.steps li, .values li, .advantages li {
  background: #fff;
  border-radius: 8px;
  margin: 10px 0;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.steps li:hover, .values li:hover, .advantages li:hover {
  transform: scale(1.02);
}

.footer {
  text-align: center;
  left: 0;
  padding: 20px;
  background-color: #ff6f61;
  color: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 2.5em;
  }

  .header h2 {
    font-size: 1.8em;
  }

  .product-intro p {
    font-size: 1em;
  }

  .steps h3, .values h3, .advantages h3 {
    font-size: 1.5em;
  }
}
